<template>
  <div class="home" id="myElement">
    <div
      class="section section1"
      :class="ifVideo && !videoLoading ? 'video-zhe' : ''"
      id="section1"
      ref="section1"
      @mousemove="handleMouseMove($event)"
      @mouseleave="handleMouseLeave($event)"
    >
      <img class="homebg1 pc" :src="require('@/assets/img/homebg1.jpg')" />
      <img class="homebg1 h5" :src="require('@/assets/imgh5/homebg1.jpg')" />

      <!-- 视频时 -->
      <video
        v-if="ifVideo && !videoLoading"
        id="video"
        class="video pc"
        :src="ifVideo"
        controls
        loop
        muted
        preload="auto"
        autoplay
        @click="handleVideoClick"
      ></video>
      <img
        v-if="ifVideo && !videoLoading"
        class="vimg1 pc"
        :src="require('@/assets/img/v-img1.png')"
      />
      <img
        v-if="ifVideo && !videoLoading"
        class="vimg2 pc"
        :src="require('@/assets/img/v-img2.png')"
      />
      <img
        v-if="ifVideo && !videoLoading"
        class="vimg3 pc"
        :src="require('@/assets/img/v-logo.png')"
      />
      <!-- 正常时 -->
      <img
        v-if="!ifVideo && !videoLoading"
        class="div1 pc"
        @click="scrollToAnchor('#section2', 2)"
        :src="require('@/assets/img/div1.png')"
      />
      <img
        class="div1 h5"
        @click="scrollToAnchor('#section2', 2)"
        :src="require('@/assets/imgh5/div1.png')"
      />
      <img
        class="div2"
        :class="ifVideo || videoLoading ? 'h5' : ''"
        :src="require('@/assets/img/div2.png')"
      />
      <img
        class="div3"
        :class="ifVideo || videoLoading ? 'h5' : ''"
        :src="require('@/assets/img/div3.png')"
      />

      <!-- 3d效果 pc 已注释 -->
      <div class="hover-div" :class="ifVideo || videoLoading ? 'h5' : ''"></div>
      <div
        ref="cardbg"
        class="h3bg-cord"
        :class="ifVideo || videoLoading ? 'h5' : ''"
      >
        <img class="h3dbg pc" :src="require('@/assets/img/3dbg.png')" />
        <img class="h3dbg h5" :src="require('@/assets/imgh5/3dbg.png')" />
      </div>
      <div
        ref="cardRef"
        class="h3-img-cord"
        :class="ifVideo || videoLoading ? 'h5' : ''"
      >
        <img class="h3dimg pc" :src="require('@/assets/img/3dimg.png')" />
        <img class="h3dimg h5" :src="require('@/assets/imgh5/3dimg.png')" />
      </div>
    </div>

    <div class="section section2" id="section2" :key="styleKey1">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg2.jpg')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg2.jpg')" />
      <img
        class="div1 div4 pc"
        @click="scrollToAnchor('#section3', 3)"
        :src="require('@/assets/img/div1.png')"
      />
      <img
        class="div1 div4 h5"
        @click="scrollToAnchor('#section3', 3)"
        :src="require('@/assets/imgh5/div1.png')"
      />

      <div class="contain2">
        <div class="swiper-container">
          <van-swipe
            class="swipe"
            ref="swipe"
            :autoplay="false"
            :show-indicators="false"
            @change="onChange"
            lazyRender
          >
            <van-swipe-item v-for="(item, index) in gameList" :key="index">
              <div
                class="game-ul"
                :class="indexImg == index ? 'active-ul' : ''"
              >
                <div class="name-contain">
                  <img
                    class="namebg"
                    :src="require('@/assets/img/gnamebg.png')"
                  />
                  <div class="gname">{{ item.game_name }}</div>
                </div>
                <div
                  class="btn g-btn"
                  v-if="item.game_url"
                  @click="handleGoWeb(item.game_url)"
                >
                  进入官网
                </div>
                <!-- <div class="game-desc">
                  {{ item.game_desc }}
                </div> -->
                <img class="gamebg" :src="item.game_banner" />
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>

        <div class="game-index">
          <div
            class="icon-index"
            :class="indexImg == index ? 'active-index' : ''"
            v-for="(item, index) in gameList"
            :key="index"
            @click="toSwipe(index)"
          >
            <img class="gicon" :src="item.game_icon" />
          </div>

          <div class="more-img" @click="handleGo('GameList', 2)">
            <img
              v-if="!loading"
              class="gicon more"
              :src="require('@/assets/img/moregame.png')"
            />
            <img
              class="gicon moreactive"
              :src="require('@/assets/img/moregamea.png')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section section3" id="section3" :key="styleKey2">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg3.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg3.jpg')" />
      <img
        class="div1 div4 pc"
        @click="scrollToAnchor('#section4', 4)"
        :src="require('@/assets/img/div1.png')"
      />
      <img
        class="div1 div4 h5"
        @click="scrollToAnchor('#section4', 4)"
        :src="require('@/assets/imgh5/div1.png')"
      />

      <img class="abouticon pc" :src="require('@/assets/img/abouticon.png')" />
      <img
        class="abouticon h5"
        :src="require('@/assets/imgh5/abouticon.png')"
      />
      <div class="text-contain">
        <p>
          浙江盛和网络科技有限公司成立于2011年，是一家集研发、运营、发行为一体的综合性轻娱乐互动娱乐企业。
        </p>
        <p>
          创立至今，公司以自主原创为基石，打造了《蓝月传奇》、《王者传奇》、《原始传奇》等多款业内知名精品手游、页游产品，研发实力受到业界广泛认可。
        </p>
        <p>
          公司秉承“让快乐更多元”的企业使命，在保留传奇类产品研发优势的同时，不断向其他游戏品类扩张，依靠核心技术的支撑，打通游戏上下游，把握研发、发行优势，通过《石器时代：觉醒》、《山海浮梦录》等不同风格的差异化产品布局多元化赛道。
        </p>
        <p>
          盛和网络始终坚持以诚信、敬业、勤奋、包容、分享、团队合作为核心的公司价值观。未来，公司将以成为一家IP-产品-发行-用户为一体的具备完整游戏生态链的公司为使命和愿景，将优秀的中国游戏带向全球。
        </p>
      </div>
      <div class="btn a-btn" @click="handleGo('auto', 4)">了解更多</div>
    </div>

    <div class="section section4" id="section4" :key="styleKey3">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg4.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg4.jpg')" />

      <!-- 左边 -->
      <img class="newsicon" :src="require('@/assets/img/newsicon.png')" />
      <div class="news-contain">
        <img class="newsbg" :src="require('@/assets/img/hwbg.png')" />
        <!-- 新闻内容 -->
        <div class="news-text">
          <div class="news-top">
            <div class="news-time">
              {{ newsData ? dateFormat(newsData.edit_time + "000", "/") : "" }}
            </div>
            <div class="news-title">{{ newsData ? newsData.title : "" }}</div>
          </div>
          <div class="new-desc">
            {{ newsData ? newsData.precis : "" }}
          </div>
          <div class="readmore">
            <img
              class="rmicon"
              @click="
                handleGo('newsDetail', 3, { id: newsData ? newsData.id : null })
              "
              :src="require('@/assets/img/readmore.png')"
            />
          </div>
        </div>
      </div>
      <div class="btn n-btn" @click="handleGo('news', 3)">查看更多新闻</div>
      <div class="right-imgs">
        <img
          class="newsimg"
          :src="
            newsData ? newsData.index_cover : require('@/assets/img/newimg.png')
          "
        />
        <img class="newsimg" :src="require('@/assets/img/nwimg.png')" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  getCurrentInstance,
  computed,
  watch,
} from "vue";
import { dateFormat } from "@/utils/dateFormat";
import "swiper/css/swiper.css";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
const canRun = ref(true);
const loading = ref(true);
// 轮播
const swipeTimer = ref();
const indexImg = ref(0);
const swipe = ref(null);
const autoplay = ref(5000);
// page几
const $index1 = computed(() =>
  proxy.$route.query.page ? Number(proxy.$route.query.page) : 1
);
// video
const $index = ref($index1.value);
// 效果展示video
const videoLoading = ref(true);
const ifVideo = ref(store.state.ifVideo);
store.commit("setIfVideo", ifVideo.value);
watch($index1, (newV) => {
  $index.value = newV;
  // 控制video播放
  if ($index.value != 1) {
    var video1 = document.getElementById("video");
    video1.pause();
  } else {
    var video2 = document.getElementById("video");
    video2.play();
  }
  // 控制菜单显示
  if ($index.value == 1 && ifVideo.value) {
    store.commit("setIfVideo", true);
  } else {
    store.commit("setIfVideo", false);
  }
  // 控制游戏轮播
  if (newV == 2) {
    indexImg.value = 0;
    toSwipe(0);
    startAutoplay();
  } else {
    clearInterval(swipeTimer.value);
  }
});
// 动画重载
const scrollPosition1 = ref(0);
const styleKey1 = ref(0);
const styleKey2 = ref(0);
const styleKey3 = ref(0);
// 初始化数据
const webId = ref();
const gameList = ref([]);
const newsData = ref({});
// 3d效果
const can3d = ref(true);
const cardbg = ref();
const cardRef = ref();
const position = reactive({ x: 0, y: 0 });
// 3d效果
const handleMouseMove = (e) => {
  if (window.innerWidth <= 750) {
    return;
  }
  position.x = e.offsetX;
  position.y = e.offsetY;
  const width = proxy.$refs.section1.offsetWidth;
  const height = proxy.$refs.section1.offsetHeight;
  let roX = position.x - width / 2;
  let roY = position.y - height / 2;
  cardRef.value.style.transition = "0.1s";
  cardbg.value.style.transition = "0.1s";
  if (can3d.value) {
    can3d.value = false;
    cardRef.value.style.transform = `translate3d(${-(roX / 30)}px, ${-(
      roY / 30
    )}px, 0px)`;
    cardbg.value.style.transform = `translate3d(${-(roX / 60)}px, ${-(
      roY / 60
    )}px, 0px)`;
    can3d.value = true;
  }
};
const handleMouseLeave = () => {
  // setTimeout(() => {
  //   cardRef.value.style.transform = "translate3d(0px, 0px, 0px)";
  //   cardbg.value.style.transform = `translate3d(0px, 0px, 0px)`;
  //   cardRef.value.style.transition = "0.5s";
  //   cardbg.value.style.transition = "0.5s";
  // }, 100);
};
// video点击
const handleVideoClick = (e) => {
  e.preventDefault();
};
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host, //"www.sh-game.com",
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        ifVideo.value = res.data.index_video;
        if ($index.value == 1 && ifVideo.value) {
          store.commit("setIfVideo", true);
        } else {
          store.commit("setIfVideo", false);
        }
        videoLoading.value = false;
        await getGame();
        await getNews();
        reslove();
      } else {
        videoLoading.value = false;
      }
    });
  });
};
// 获取游戏轮播
const getGame = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
      type: "index",
    };
    proxy.$axios.post("/Index/getGames", params).then((res) => {
      if (res.code == 200) {
        gameList.value = res.data;
        reslove();
      }
    });
  });
};
// 获取最新资讯
const getNews = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
      type: "index",
    };
    proxy.$axios.post("/Index/getNews", params).then((res) => {
      if (res.code == 200) {
        newsData.value = res.data[0];
        reslove();
      }
    });
  });
};
// 跳转游戏官网
const handleGoWeb = (val) => {
  window.location = val;
};
const startAutoplay = () => {
  swipeTimer.value = setInterval(() => {
    if (indexImg.value < gameList.value.length - 1) {
      indexImg.value = indexImg.value + 1;
    } else {
      indexImg.value = 0;
    }
    toSwipe(indexImg.value == 0 ? gameList.value.length : indexImg.value);
  }, autoplay.value);
};
const onChange = (index) => {
  if (swipeTimer.value) {
    clearInterval(swipeTimer.value);
    startAutoplay();
  }
  indexImg.value = index;
};
const toSwipe = (index) => {
  swipe.value.swipeTo(index);
};
// 跳转页面
const handleGo = (val, n, query) => {
  proxy.$router.push({
    path: val,
    query: query,
  });
  store.commit("setActiveIndex", n);
};
// 滑动监测
const handleScroll = () => {
  const containerHeight = window.innerHeight;
  const scrollPosition = document.documentElement.scrollTop;
  if (scrollPosition <= scrollPosition1.value) {
    return;
  }
  scrollPosition1.value = scrollPosition;
  // 假设您有一个元素的ID为'myElement'
  const element = document.getElementById("myElement");
  const wHeight = element.offsetHeight;
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    if (
      scrollPosition >= 0 &&
      scrollPosition <= (wHeight - containerHeight) * 0.25
    ) {
      styleKey1.value = styleKey1.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.25 &&
      scrollPosition <= (wHeight - containerHeight) * 0.5
    ) {
      styleKey2.value = styleKey2.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.5 &&
      scrollPosition < (wHeight - containerHeight) * 0.75
    ) {
      styleKey3.value = styleKey3.value + 1;
    }
  } else {
    if (
      scrollPosition >= 0 &&
      scrollPosition <= (wHeight - containerHeight) * 0.25
    ) {
      styleKey1.value = styleKey1.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.25 &&
      scrollPosition <= (wHeight - containerHeight) * 0.5
    ) {
      styleKey2.value = styleKey2.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.5 &&
      scrollPosition < (wHeight - containerHeight) * 0.75
    ) {
      if ($index.value == 5) {
        return;
      }
      styleKey3.value = styleKey3.value + 1;
    }
  }
};
// 判断是否移动端
const ifH5 = () => {
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
// 鼠标滑动监测
const handleWheel = (e) => {
  if (ifH5()) return;
  e.preventDefault();
  if (canRun.value) {
    canRun.value = false;
    goScroll(e);
    setTimeout(() => {
      canRun.value = true;
    }, 500);
  }
};
const goScroll = (e) => {
  //e.wheelDelta 用来判断上一个下一个 <0 下一个 >0上一个
  if (e.wheelDelta < 0) {
    next();
  } else {
    last();
  }
};
// 下一个
const next = () => {
  if ($index.value < 4) {
    var element = document.getElementById(`section${$index.value}`);
    // 获取元素的总高度（包括内边距、边框和滚动条）
    var totalHeight = element.offsetHeight;
    window.scrollBy({ top: totalHeight, behavior: "smooth" });
    $index.value++;
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
  } else if ($index.value == 4) {
    $index.value++;
    window.scrollBy({ top: ifH5() ? 500 : 400, behavior: "smooth" });
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
  }
};
// 上一个
const last = () => {
  if ($index.value == 5) {
    $index.value--;
    window.scrollBy({
      top: Number(`-${ifH5() ? 500 : 400}`),
      behavior: "smooth",
    });
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
    return;
  }
  if ($index.value > 1) {
    $index.value--;
    proxy.$router.replace({
      path: "/",
      query: { page: $index.value },
    });
    var element = document.getElementById(`section${$index.value}`);
    // 获取元素的总高度（包括内边距、边框和滚动条）
    var totalHeight = element.offsetHeight;
    window.scrollBy({ top: -totalHeight, behavior: "smooth" });
  }
};
const scrollToAnchor = (id, n) => {
  proxy.$router.replace({
    path: "/",
    query: { page: n },
  });
  document.querySelector(id).scrollIntoView({ behavior: "smooth" });
};
onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("wheel", handleWheel, { passive: false });
  store.commit("setLoad", false);
  setTimeout(() => {
    loading.value = false;
  }, 1000);
  await initData();
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("wheel", handleWheel);
  clearInterval(swipeTimer.value);
});
</script>
<style src="./home.css" scoped></style>
<style scoped>
</style>
